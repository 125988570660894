.mantener-hojas-ruta {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}

.mantener-hoja-ruta-dialog {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
  .estado-documentacion-label,
  .alerta-visual {
    font-weight: bold;
    color: red;
  }
}
