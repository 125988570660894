.transferencia-ubicacion {
  // :global(.p-divider.p-divider-horizontal) {
  //   margin: 0 !important;
  // }
  .p-fieldset-legend {
    // Edita o estilo do header do fieldset
    width: 20%; // tamanho da caixa
    font-size: 1.5rem; // tamanho da fonte
  }
  .p-tag {
    margin: 1rem;
    font-size: 1rem; /* Ajuste o tamanho da fonte conforme necessário */
    padding: 0.5rem 1rem; /* Ajuste o padding conforme necessário */
  }

  .filter-options {
    display: flex;
    flex-direction: column; /* Faz com que as linhas fiquem uma em cima da outra */
    gap: 2rem;
    align-items: stretch;
    padding: 2rem;

    .sipco-options-line {
      display: flex;
      flex-direction: row; /* Mantém os elementos dentro da linha em linha */
      width: 100%;
      gap: 2rem; /* Ajuste o gap conforme necessário */
    }
    .divide {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 50%; /* Aproximadamente metade do espaço disponível */
      // max-width: 50%; /* Garante que a .divide não ocupe mais que metade do espaço disponível */
      gap: 1rem; /* Espaço entre as .sipco-option-divide */

      .sipco-option-divide {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 0.5rem; /* Espaço interno entre o conteúdo do .sipco-option-divide */
      }
      
    }

    .sipco-option-extra-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;
      justify-content: flex-end;
    }
    .sipco-option-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 62%;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: flex-end;
    }
    .sipco-option {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;

      .p-autocomplete-multiple-container.p-component.p-inputtext {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      .p-autocomplete-input.p-inputtext.p-component {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      label {
        gap: 0.5rem;
      }
    }
    .RadioButtonFilter {
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;
    }
    .button {
      display: flex;
      justify-content: center;
      max-width: 40%;
    }
    .sipco-option-button {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
 
  .p-tabview-nav{
    justify-content: center;
    
  }

  // .p-dialog-mask.p-dialog-center.p-component-overlay.p-component-overlay-enter.p-dialog-draggable.p-dialog-resizable{
  //   position: fixed;
  //   height: 100%;
  //   width: 100%;
  //   left: 0px;
  //   top: 0px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   z-index: 1101;
  // }
  
//   .p-dialog-enter-done {
//     transform: none;
// }
// .p-hidden-accessible {
//   border: 0;
//   padding: 0;
//   margin: -1px;
//   position: absolute;
//   height: 1px;
//   width: 1px;
//   overflow: hidden;
//   clip: rect(0, 0, 0, 0);
//   clip-path: inset(50%);
//   white-space: nowrap;
// }
.p-dialog {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  max-height: 90%;
  transform: scale(1);
  position: relative;
}

.p-dialog-header {
  border-bottom: 0 none;
  padding: 1.5rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-dialog-content {
  padding: 0 1.5rem 2rem 1.5rem;
}
.p-dialog-footer {
  border-top: 0 none; 
  justify-content: flex-end;
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-resizable-handle {
    position: absolute;
    font-size: 0.1px;
    display: block;
    cursor: se-resize;
    width: 12px;
    height: 12px;
    right: 1px;
    bottom: 1px;
}

.p-dialog-title {
  flex-grow: 1;
}

.p-datatable {
  display: grid;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
      .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-width: 0 0 1px 1px;
    max-width: 150px;
  }
  
}
