.reingreso-stock {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
  .card {
    margin: 1rem;
    width: 400px;
  }

  .panel-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 100%;
    width: 100%;
  }
  .panel-container > .panel {
    flex: 1;
  }

  .p-datatable {
    display: grid;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
      .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-width: 0 0 1px 1px;
    max-width: 150px;
  }
}
