.carga-op-forzadas {  

  .horizontal-band {
    display: flex;
    justify-content: left;
    width: 100%;
    margin-bottom: 10px;
    min-height: 3px;
    margin-right: 10px;
  }

  .footer-horizontal-band {
    flex: 1;
    display: flex;
    align-items: center;
    padding-top: 16px;
  }

  .style-footer {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .button-import-file {
    display: none;
    margin-left: 30px;
  }

  .sipco-option {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    margin: 8px 14px;
    min-width: 200px;

    .p-autocomplete-multiple-container.p-component.p-inputtext {
      width: 100%;
      height: 3rem;
      /* Ajuste para o tamanho desejado */
      overflow: auto;
    }

    .p-autocomplete-input.p-inputtext.p-component {
      width: 100%;
      height: 3rem;
      /* Ajuste para o tamanho desejado */
      overflow: auto;
    }

    label {
      gap: 0.5rem;
    }
  }

  .width100 {
    width: 100%;
  }

  .marginTop{
    margin-top: 20px;
  }

  .p-datatable {
    display: grid;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
      .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-width: 0 0 1px 1px;
    max-width: 150px;
  }
}
