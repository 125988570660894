.modalAltaReparacionServicios {
  .modal-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 auto;
    max-width: 500px;

    .form-modal {
      display: flex;
      flex-direction: row;
      gap: 15px;
      justify-content: center;

      .form-row-modal {
        display: flex;
        gap: 10px;
        align-items: center;

        .input-modal {
          width: 250px;
        }

        .sipco-option-modal {
          display: flex;
          flex-direction: column;
          gap: 5px;
          flex: 1;
          max-width: 300px;
        }
      }
    }
  }
}
