.colecta {
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 800px;
    margin: 0 auto;
  }

  .form {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .form-left,
  .form-right {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .sipco-option {
    display: flex;
    flex-direction: column;
    max-width: 300px;
  }

  .sipco-option-row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
  }

  .move-right {
    margin-left: 170px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .sipco-option-left-label .p-calendar {
    width: auto;
    flex: 1;
    min-width: 150px;
  }

  .sipco-option-left-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .sipco-option-left-label label {
    min-width: 120px;
    text-align: left;
  }

  .form-row {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .RadioButtonFilter {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 10px;
  }

  .highlight-row {
    background-color: #ecfeff;
    cursor: pointer;
  }

  .button-container {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #ccc;
  }

  .button-container button {
    padding: 0.6rem 1rem;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
  }

  // hover buttons
  .green-button:hover {
    background-color: #006400;
  }
  .yellow-button:hover {
    background-color: #8b8000;
  }
  .red-button:hover {
    background-color: #8b0000;
  }

  // lines dt
  .tab-header-active {
    border-bottom: 3px solid #3b82f6; //default
  }
  .color-colecta-quitada-del-jumelage {
    font-weight: bold;
    color: #438ba1;
  }

  // tags
  .tags {
    margin-bottom: 15px;
  }
  .dataTable-spacing {
    margin-bottom: 15px !important;
  }
  .tag-colecta-quitada-del-jumelage {
    margin-right: 5px;
    background-color: #438ba1;
  }
}