.despacho-mercado-nacional {
  :global(.p-divider.p-divider-horizontal) {
    margin: 0 !important;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }

  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;

    .sipco-options-line {
      display: flex;
      flex-direction: row;
      @media (min-width: 1281px) {
        max-width: 75%;
      }
      align-items: flex-end;
      gap: 2rem;
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 30%; /* Ajusta o espaço para dividir igualmente */
      max-width: 30%;
      gap: 1rem;
    }

    .divide {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 48%; /* Aproximadamente metade do espaço disponível */
      max-width: 48%; /* Garante que a .divide não ocupe mais que metade do espaço disponível */
      gap: 1rem; /* Espaço entre as .sipco-option-divide */

      .sipco-option-divide {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 0.5rem; /* Espaço interno entre o conteúdo do .sipco-option-divide */
        justify-content: flex-end;
        max-width: 30%;
      }
    }

    .sipco-option-extra-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;
      justify-content: flex-end;
    }

    .sipco-option-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: flex-end;
    }

    .p-autocomplete-multiple-container.p-component.p-inputtext,
    .p-autocomplete-input.p-inputtext.p-component {
      width: 100%;
      height: 3rem;
      overflow: auto;
    }

    label {
      flex: 1; // Permite que a label ocupe o espaço disponível necessário para seu conteúdo
      white-space: nowrap; // Impede a quebra de linha do texto
      overflow: hidden; // Esconde o texto que excede o tamanho da label, se necessário
      text-overflow: ellipsis; // Adiciona reticências no final se o texto for muito longo
    }

    .RadioButtonFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      label {
        flex: 1; // Permite que a label ocupe o espaço disponível necessário para seu conteúdo
        white-space: nowrap; // Impede a quebra de linha do texto
        overflow: hidden; // Esconde o texto que excede o tamanho da label, se necessário
        text-overflow: ellipsis; // Adiciona reticências no final se o texto for muito longo
      }
    }
  }

  .p-panel-footer {
    .p-accordion-header-text {
      display: flex;
      align-items: center;
      height: 3rem;
    }
    .p-accordion-header-link > span > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .p-accordion-header-link {
      padding: 0.5rem 1.5rem;
    }

    .filter-options {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      align-items: stretch;

      .sipco-options-line {
        display: flex;
        flex-direction: row;
        @media (min-width: 1280px) {
          max-width: 75%;
        }
        align-items: flex-end;
        gap: 2rem;
      }

      .sipco-option {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 30%; /* Ajusta o espaço para dividir igualmente */
        max-width: 30%;
        gap: 1rem;
      }

      .divide {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 48%; /* Aproximadamente metade do espaço disponível */
        max-width: 48%; /* Garante que a .divide não ocupe mais que metade do espaço disponível */
        gap: 1rem; /* Espaço entre as .sipco-option-divide */

        .sipco-option-divide {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          gap: 0.5rem; /* Espaço interno entre o conteúdo do .sipco-option-divide */
          justify-content: flex-end;
          max-width: 30%;
        }
      }

      .sipco-option-extra-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.5rem;
        flex-grow: 1;
        max-width: 75%;
        flex-shrink: 1;
        flex-basis: 20%;
        justify-content: flex-end;
      }

      .sipco-option-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.5rem;
        flex-grow: 1;
        max-width: 30%;
        flex-shrink: 1;
        flex-basis: 0%;
        justify-content: flex-end;
      }

      .p-autocomplete-multiple-container.p-component.p-inputtext,
      .p-autocomplete-input.p-inputtext.p-component {
        width: 100%;
        height: 3rem;
        overflow: auto;
      }

      label {
        gap: 0.5rem;
      }

      .RadioButtonFilter {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .buttons {
      margin-top: 2rem;
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
  }

  .tags {
    display: inline-flex;
    padding: 0.05rem;
    gap: 1rem;
    align-items: flex-end;
    .p-tag {
      font-size: 1rem;
    }
  }

  .chips {
    display: flex;
    .sipco-chip {
      flex-direction: row;
      padding: 0.05rem;
      gap: 1rem;
      .p-chip {
        margin-left: 1rem;
        min-width: 5rem;
      }
    }
  }

  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
      .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-width: 0 0 1px 1px;
    max-width: 150px;
  }
}
