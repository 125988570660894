.devolucion-mercado-local {
  :global(.p-divider.p-divider-horizontal) {
    margin: 0 !important;
  }


  .filter-options {
    display: flex;
    flex-direction: column; /* Faz com que as linhas fiquem uma em cima da outra */
    gap: 2rem;
    align-items: stretch;
    padding: 2rem;

    .sipco-options-line {
      display: flex;
      flex-direction: row; /* Mantém os elementos dentro da linha em linha */
      width: 100%;
      gap: 2rem; /* Ajuste o gap conforme necessário */
    }


    .sipco-option {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 20%;
      flex-shrink: 1;
      flex-basis: 20%;

      .p-autocomplete-multiple-container.p-component.p-inputtext {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      .p-autocomplete-input.p-inputtext.p-component {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      label {
        gap: 0.5rem;
      }
    }
    .RadioButtonFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
